export const APP_CONST = Object.freeze({
  AUTH_ACTIONS: {
    LOGIN: "login",
    REGISTER: "register",
  },
  TOAST_TYPE: {
    ERROR: "error",
    SUCCESS: "success",
    INFO: "info",
    WARNING: "warning",
  },
  SPORTS_ID: {
    CIRCKET: "4",
    SOCCER: "1",
    TENNIS: "2",
  },
  ALL_MARKET_IDS: {
    CRICKET: "CRICKET_IDS",
    SOCCER: "SOCCER_IDS",
    TENNIS: "TENNIS_IDS",
  },
  ODDS_TYPE: {
    BACK: "back",
    LAY: "lay",
  },
  SPORTS_CATEGORY: {
    CRICKET: "cricket",
    TENNIS: "tennis",
    SOCCER: "soccer",
    INPLAY: "inplay",
  },

  PLAYER_BANK_DETAILS_TYPE: {
    Bank: "bank",
    Card: "card",
  },

  GATEWAYS: {
    M_BKASH: "m_bkash",
    M_NAGAD: "m_nagad",
    M_Paytm: "m_paytm",
    M_BinanceTron: "m_binance_tron",
    M_BinanceBSC: "m_binance_bsc",
  },

  ORGANISATION_STATUS: {
    Maintenance: "maintenance",
    Live: "live",
    Suspended: "suspended",
  },

  ENVIRONMENTS: {
    PRODUCTION: "production",
    DEVELOPMENT: "development",
  },
  AUTH_MODAL: {
    LOGIN: "login",
    REGISTER: "register",
    MOBILE_NUMBER: "mobileNumber",
    VERIFY_NUMBER: "verifyNumber",
    FORGOTO_PASSWORD: "forgotpassword",
    RESET_PASSWORD: "resetpassword",
  },
  AUTH_MODAL_ACTION_TYPE: {
    MANUAL: "manual",
    AUTO: "auto",
  },
});

export const INPUT_TYPE = {
  FILE: "file",
  TEXT: "text",
  DATE: "date",
};

export const KYC_DOCUMENT_TYPE = {
  PASSPORT: "passport",
  EMIRATES_ID: "emiratesId",
};

export const KYC_VERIFICATION_STATUS = Object.freeze({
  Pending: "pending",
  Approved: "approved",
  Declined: "declined",
  Expired: "expired",
});

export const APP_ROUTES_TYPE = Object.freeze({
  PROTECTED: "protected",
  PUBLIC: "public",
  COMMON: "common",
});

export const APP_DEFAULT_LAYOUT_SETTING = Object.freeze({
  header: true,
  footer: true,
});

export const DEFAULT_APP_ROUTES = Object.freeze({
  PUBLIC: "/",
  PROTECTED: "/home",
});

export const APK_LOCAL_STORAGE_KEY = "isApk";
