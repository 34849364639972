export const pageUrlGenerator = (page) =>
  `${window.location.protocol}//${window.location.host}${page}`;

export const casinoTabs = {
  popular: {
    MonopolyLivePR: {
      href: "/casino/ezgevo-monopoly-live",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/popular/new/MonopolyLive.webp",
      alt: "monopoly live",
      name: "Monopoly Live",
    },
    OneDayTeenPattiPR: {
      href: "/casino/ezg-namaste-roulette",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/popular/new/NamasteRoulette.jpg",
      alt: "Namaste Roulette",
      name: "Namaste Roulette",
    },
    AviatorPR: {
      href: "/casino/spribe/aviator",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/popular/new/aviator.webp",
      alt: "aviator",
      name: "Aviator",
    },
    Ultimateandarbahar: {
      href: "/casino/ezg-ultimate-andar-bahar",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/popular/new/Ultimateandarbahar.jpg",
      alt: "ultimate andar bahar",
      name: "Ultimate Andar Bahar",
    },
    Cards32PR: {
      href: "/casino/ezg-32-cards",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/popular/new/32cards.webp",
      alt: "32 cards",
      name: "32 Cards",
    },
    CrazyTimePR: {
      href: "/casino/ezgevo-crazy-time",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/popular/new/CrazyTime.webp",
      alt: "crazy time",
      name: "Crazy Time",
    },
    DoubleBallRoulettePR: {
      href: "/casino/ezgevo-double-ball-roulette",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/popular/new/doubleballrouolette.webp",
      alt: "double ball roulette",
      name: "Double Ball Roulette",
    },
    DragonTiger1PR: {
      href: "/casino/ezg-dragon-tiger",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/popular/new/DragonTiger.webp",
      alt: "dragon tiger 1",
      name: "Dragon Tiger 1",
    },
    ItalianRoulettePR: {
      href: "/casino/ezg-italian-roulette",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/popular/new/italianroulette.webp",
      alt: "italian roulette",
      name: "Italian Roulette",
    },
    Lucky7PR: {
      href: "/casino/ezg-lucky-7",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/popular/new/Lucky7.webp",
      alt: "seven lucky",
      name: "Seven Lucky",
    },
    GonzosQuest: {
      href: "/casino/ezg-gonzosquest",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/popular/new/GonzosQuest.webp",
      alt: "GonzosQuest",
      name: "Gonzo's Quest",
    },
    MysteryReels: {
      href: "/casino/ezgrt-mystery-reels",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/popular/new/MysteryReels.webp",
      alt: "MysteryReels",
      name: "Mystery Reels",
    },
    GoodOld7s: {
      href: "/casino/qtechnetent-good-old-7s-u8588",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/popular/new/GoodOld7s.webp",
      alt: "GoodOld7s",
      name: "Good Old 7's",
    },
    DeadorAlive: {
      href: "/casino/ezgne-dead-or-alive",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/popular/new/DeadorAlive.webp",
      alt: "DeadorAlive",
      name: "Dead or Alive",
    },
    Starburst: {
      href: "/casino/ezg-starburst",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/popular/new/Starburst.webp",
      alt: "Starburst",
      name: "Star burst",
    },
  },
  ezugi: {
    UltimateRouletteEZ: {
      href: "/casino/ezg-ultimate-roulette",
      code: "",
      casino: "ezugi",
      provider: "Ezugi",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/ezugi/Ultimateroulette.webp",
      alt: "ultimate roulette",
      name: "Ultimate Roulette",
    },
    DiamondRouletteEZ: {
      href: "/casino/ezg-diamond-roulette",
      code: "",
      casino: "ezugi",
      provider: "Ezugi",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/ezugi/diamondrou.webp",
      alt: "diamond roulette",
      name: "Diamond Roulette",
    },
    NamasteRouletteEZ: {
      href: "/casino/ezg-namaste-roulette",
      code: "",
      casino: "ezugi",
      provider: "Ezugi",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/ezugi/NamasteRoulette.webp",
      alt: "namaste roulette",
      name: "Namaste Roulette",
    },
    AutoRouletteEZ: {
      href: "/casino/ezg-auto-roulette",
      code: "",
      casino: "ezugi",
      provider: "Ezugi",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/ezugi/AutoRoulette.webp",
      alt: "auto roulette",
      name: "Auto Roulette",
    },
    SpeedAutoRouletteEZ: {
      href: "/casino/ezg-speed-auto-roulette",
      code: "",
      casino: "ezugi",
      provider: "Ezugi",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/ezugi/SpeedRoulette.webp",
      alt: "speed auto roulette",
      name: "Speed Auto Roulette",
    },
    OracleRoulette2EZ: {
      href: "/casino/ezg-oracle-360-roulette",
      code: "",
      casino: "ezugi",
      provider: "Ezugi",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/ezugi/oracle360.webp",
      alt: "oracle roulette 2",
      name: "Oracle Roulette 2",
    },
    ItalianRouletteEZ: {
      href: "/casino/ezg-italian-roulette",
      code: "",
      casino: "ezugi",
      provider: "Ezugi",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/ezugi/italianroulette.webp",
      alt: "italian roulette",
      name: "Italian Roulette",
    },
    SpanishRouletteEZ: {
      href: "/casino/ezg-spanish-roulette",
      code: "",
      casino: "ezugi",
      provider: "Ezugi",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/ezugi/SpanishRoulette.webp",
      alt: "spanish roulette",
      name: "Spanish Roulette",
    },
  },
  evolution: {
    InstantRouletteEvo: {
      href: "/casino/ezgevo-instant-roulette",
      code: "",
      casino: "evolution",
      provider: "Evolution",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/evolution/instantroulette.webp",
      alt: "instant roulette",
      name: "Instant Roulette",
    },
    DragonTigerEvo: {
      href: "/casino/ezgevo-dragon-tiger-evo",
      code: "",
      casino: "evolution",
      provider: "Evolution",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/evolution/dragontiger.webp",
      alt: "dragon tiger evo",
      name: "Dragon Tiger Evo",
    },
    BaccaratAEvo: {
      href: "/casino/ezgevo-baccarat-a",
      code: "",
      casino: "evolution",
      provider: "Evolution",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/evolution/baccarat.webp",
      alt: "baccarat a",
      name: "Baccarat A",
    },
    BlackjackAEvo: {
      href: "/casino/ezgevo-blackjack-a",
      code: "",
      casino: "evolution",
      provider: "Evolution",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/evolution/blackjack.webp",
      alt: "blackjack a",
      name: "Blackjack A",
    },
    CrazyTimeEvo: {
      href: "/casino/ezgevo-crazy-time",
      code: "",
      casino: "evolution",
      provider: "Evolution",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/evolution/CrazyTime.webp",
      alt: "crazy time",
      name: "Crazy Time",
    },
    MegaballEvo: {
      href: "/casino/evolution/Megaball",
      code: "",
      casino: "evolution",
      provider: "Evolution",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/evolution/megaball.webp",
      alt: "megaball",
      name: "Megaball",
    },
    SuperSicBoEvo: {
      href: "/casino/ezgevo-super-sic-bo",
      code: "",
      casino: "evolution",
      provider: "Evolution",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/evolution/supersicobo.webp",
      alt: "super sic bo",
      name: "Super Sic Bo",
    },
  },
  spribe: {
    mines: {
      href: "/casino/spribe/mines",
      code: "mines",
      casino: "spribe",
      provider: "mines",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/spribe/Mines.webp",
      alt: "mines",
      name: "Mines",
    },
    goal: {
      href: "/casino/spribe/goal",
      code: "goal",
      casino: "spribe",
      provider: "goal",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/spribe/Goal.webp",
      alt: "goal",
      name: "Goal",
    },
    dice: {
      href: "/casino/spribe/dice",
      code: "dice",
      casino: "spribe",
      provider: "dice",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/spribe/Dice.webp",
      alt: "dice",
      name: "Dice",
    },
    aviator: {
      href: "/casino/spribe/aviator",
      code: "aviator",
      casino: "spribe",
      provider: "aviator",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/spribe/Aviator.webp",
      alt: "aviator",
      name: "Aviator",
    },
    plinko: {
      href: "/casino/spribe/plinko",
      code: "plinko",
      casino: "spribe",
      provider: "plinko",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/spribe/Plinko.webp",
      alt: "plinko",
      name: "Plinko",
    },
    miniroulette: {
      href: "/casino/spribe/miniroulette",
      code: "miniroulette",
      casino: "spribe",
      provider: "miniroulette",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/spribe/MiniRoulette.webp",
      alt: "mini roulette",
      name: "Mini Roulette",
    },
    hilo: {
      href: "/casino/spribe/hilo",
      code: "hilo",
      casino: "spribe",
      provider: "hilo",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/spribe/Hilo.webp",
      alt: "hilo",
      name: "Hilo",
    },
  },
  aura: {
    BaccaratAura: {
      href: "/casino/aura-baccarat",
      code: "",
      casino: "Aura Gaming",
      provider: "Aura",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/aura/baccarat.webp",
      alt: "baccarat",
      name: "Baccarat",
    },
    DreamCatcherAura: {
      href: "/casino/aura-dream-catcher",
      code: "",
      casino: "Aura Gaming",
      provider: "Aura",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/aura/Dreamcatcher.webp",
      alt: "dream catcher",
      name: "Dream Catcher",
    },
    HiLowAura: {
      href: "/casino/aura-hi-low",
      code: "",
      casino: "Aura Gaming",
      provider: "Aura",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/aura/highlow.webp",
      alt: "hi low",
      name: "Hi Low",
    },
    MatkaAura: {
      href: "/casino/aura-matka",
      code: "",
      casino: "Aura Gaming",
      provider: "Aura",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/aura/matka.webp",
      alt: "matka",
      name: "Matka",
    },
    TeenpattiOpenAura: {
      href: "/casino/aura-teenpatti-open",
      code: "",
      casino: "Aura Gaming",
      provider: "Aura",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/aura/openteenpatti.webp",
      alt: "teenpatti open",
      name: "Teenpatti Open",
    },
    Race2020Aura: {
      href: "/casino/aura-race-20-20",
      code: "",
      casino: "Aura Gaming",
      provider: "Aura",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/aura/race2020.webp",
      alt: "race 20 20",
      name: "Race 20 20",
    },
    TrioAura: {
      href: "/casino/aura-trio",
      code: "",
      casino: "Aura Gaming",
      provider: "Aura",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/casino/aura/trio.webp",
      alt: "trio",
      name: "Trio",
    },
  },
  sports: {
    InplayCricket: {
      href: "/exchange/Inplay",
      code: "",
      casino: "",
      provider: "",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/sports/InPlay.webp",
      alt: "inplay",
      name: "Inplay",
    },
    Cricket: {
      href: "/exchange/Cricket",
      code: "",
      casino: "",
      provider: "",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/sports/Cricket.webp",
      alt: "cricket",
      name: "Cricket",
    },
    Soccer: {
      href: "/exchange/Soccer",
      code: "",
      casino: "",
      provider: "",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/sports/Football.webp",
      alt: "soccer",
      name: "Soccer",
    },
    Tennis: {
      href: "/exchange/Tennis",
      code: "",
      casino: "",
      provider: "",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/sports/Tennis.webp",
      alt: "tennis",
      name: "Tennis",
    },
    InplayCricket2: {
      href: "/exchange/Inplay",
      code: "",
      casino: "",
      provider: "",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/sports/InPlay.webp",
      alt: "inplay",
      name: "Inplay",
    },
    Cricket2: {
      href: "/exchange/Cricket",
      code: "",
      casino: "",
      provider: "",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/sports/Cricket.webp",
      alt: "cricket",
      name: "Cricket",
    },
    Soccer2: {
      href: "/exchange/Soccer",
      code: "",
      casino: "",
      provider: "",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/sports/Football.webp",
      alt: "soccer",
      name: "Soccer",
    },
    Tennis2: {
      href: "/exchange/Tennis",
      code: "",
      casino: "",
      provider: "",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/sports/Tennis.webp",
      alt: "tennis",
      name: "Tennis",
    },
    Greyhound: {
      href: "/exchange/Greyhound%20Racing",
      code: "",
      casino: "",
      provider: "",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/sports/greyhoundracing.webp",
      alt: "tennis",
      name: "Greyhound",
    },
    Horseracing: {
      href: "/exchange/Horse%20Racing",
      code: "",
      casino: "",
      provider: "",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/sports/HorseRacing.webp",
      alt: "Horseracing",
      name: "Horse Racing",
    },
  },
  providers: {
    ezugi: {
      href: "/casino/ezugi",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/providers/Ezugi.webp",
      name: "Ezugi",
      quotes:
        "Experience the excitement at Ezugi Casino, where every game is live and thrilling. Join us to enjoy real-time action and endless opportunities for big wins!",
    },
    evolution: {
      href: "/casino/evolution",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/providers/Evolution.webp",
      name: "Evolution",
      quotes:
        "Join Evolution Casino for an unmatched gaming experience where innovation meets excitement. Every game is a new opportunity, and every win evolves your adventure!",
    },
    vivo: {
      href: "/casino/vivo",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/providers/VivoLogo.webp",
      name: "Vivo",
      quotes:
        "Experience the ultimate thrill at Vivo Gaming Casino, where cutting-edge technology meets unparalleled excitement. Play now and turn every game into a winning adventure!",
    },
    qtech: {
      href: "/casino/qtech",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/providers/qtech.webp",
      name: "Qtech",
      quotes:
        "Discover the future of gaming at QTech Casino, where innovation and excitement meet. Dive into a world of thrilling games and endless opportunities for big wins!",
    },
    supernowa: {
      href: "/casino/supernowa",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/providers/supernowa.webp",
      name: "Supernowa",
      quotes:
        "Unleash your winning potential at Supernowa Casino, where every game shines bright with excitement and every spin brings you closer to extraordinary rewards!",
    },
    xpg: {
      href: "/casino/xpg",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/providers/XPG.webp",
      name: "XPG",
      quotes:
        "Step into the world of XPG Casino, where cutting-edge gaming meets unmatched excitement. Every game is a new adventure, and every win brings you closer to greatness!",
    },
    worldcasino: {
      href: "/casino/worldcasino",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/providers/WorldCasino.webp",
      name: "World Casino",
      quotes:
        "Discover endless excitement at World Casino, where every game is a journey to big wins and unforgettable moments. Join us and make your mark on the world of gaming!",
    },
    // aesexy: {
    //   href: "/casino/aesexy",
    //   code: "",
    //   casino: "ezugi",
    //   provider: "",
    //   homeUrl: pageUrlGenerator("/home"),
    //   imgUrl: "./images/providers/aesexy.jpg",
    //   name: "AE Sexy",
    //   quotes:
    //     "Experience the thrill and elegance of AE Sexy, where every game is a captivating adventure, and every win adds a touch of allure. Join us for unforgettable moments!",
    // },
    powergames: {
      href: "/casino/powergames",
      code: "",
      casino: "ezugi",
      provider: "",
      homeUrl: pageUrlGenerator("/home"),
      imgUrl: "./images/providers/PowerGame.webp",
      name: "Power Games",
      quotes:
        "Unleash your potential and experience the thrill of winning at Power Games Casino, where every game is a new adventure and every spin brings you closer to your next big win!",
    },
  },
};
